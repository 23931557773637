<template>
  <ion-page>
    <ion-content>
      <PageHeader bg="bg-yellow" title="Privacy Policy">
        <template slot="button">
          <button @click="closeModal" class="mb-2">
            <ArrowLeftIcon/>
          </button>
        </template>
      </PageHeader>
      <RoundedBigCard class="-mt-8 relative z-10">
        <template slot="content">
          <h5 class="font-bold mb-2">Privacy Policy for Daily Feel Goods LLC</h5>
          <p class="mb-5 italic">Updated: Feb 12th, 2019</p>
          <p class="mb-5">At kirehab.com, we recognize that privacy of your personal information is important. 
            Here is information on what types of personal information we receive and collect when you visit the site, and how we safeguard your information. 
            We will never sell your personal information to third parties.</p>
          <p class="mb-5">1. INTRODUCTION</p>
          <p class="mb-5">1.1. PURPOSE OF POLICY. kirehab.com (“us,” “we,” or “Company”) is committed to respecting the privacy rights of its customers, visitors, and other users of the Company Website (the “Site”). We created this Website Privacy Policy (this “Policy”) to give you confidence as you visit and use the Site, and to demonstrate our commitment to fair information practices and the protection of privacy. This Policy is only applicable to the Site, and not to any other websites that you may be able to access from the Site, each of which may have data collection and use practices and policies that differ materially from this Policy.</p>
          <p class="mb-5">1.2. NOTICE CONCERNING CHILDREN</p>
          <p class="mb-5">PLEASE NOTE: We are a general audience site, and do not direct any of our content specifically at children under 13 years of age. We understand and are committed to respecting the sensitive nature of children’s privacy online. If we learn or have reason to suspect that a Site user is under age 13, we will promptly delete any personal information in that user’s account.</p>
          <p class="mb-5">2. INFORMATION COLLECTION PRACTICES</p>
          <p class="mb-5">2.1. WHAT BASIC INFORMATION DOES THE COMPANY COLLECT?
In operating the Site, Company may include newsletters, online commenting areas, and other online forms that ask users to provide their names, e-mail addresses, and other contact information.</p>
          <p class="mb-5">2.2. WHAT ADDITIONAL INFORMATION DOES COMPANY COLLECT?
(a) AUTOMATIC COLLECTION. Our servers automatically recognize visitors’ domain names and IP addresses (the number assigned to computers on the Internet). No personal information about you is revealed in this process. The Site may also gather anonymous “traffic data” that does not personally identify you, but that may be helpful for marketing purposes or for improving the services we offer.
(b) COOKIES. From time to time, we may use the standard “cookies” feature of major browser applications that allows us to store a small piece of data on your computer about your visit to our Web site. Cookies help us learn which areas of our site are useful and which areas need improvement. You can choose whether to accept cookies by changing the settings on your browser. However, if you choose to disable this function, your experience at our Web site may be diminished and some features may not work as they were intended.</p>
          <p class="mb-5">2.3 Merchant will ensure that at all times during the term of this agreement, Merchant has a clearly labeled and easily accessible privacy policy in place relating to the Site(s) and that this privacy policy: (i) clearly discloses to End Users that third parties may be placing and reading cookies on End Users’ browsers, or using web beacons or similar technologies to collect information in connection with the Google Trusted Stores program; and (ii) includes information about End Users’ options for cookie management.</p>
          <p class="mb-5">3. USE AND SHARING OF INFORMATION</p>
          <p class="mb-5">3.1. WHAT DOES COMPANY DO WITH COLLECTED INFORMATION?</p>
          <p class="mb-5">(a) PERSONAL INFORMATION. We will not share your personal information with any third parties without your consent, except as necessary to provide you with the services offered by us or to comply with the law. We will use your information as a greater collection of data related to the site in traffic discussions in articles posted on the blog. No individual users visit, whether you or another visitor, are discussed. The aggregate data is used for education purposes only and no reader of the article will be able to identify you.
<br>(b) ANONYMOUS INFORMATION. We use anonymous information to analyze our Site traffic. In addition, we may use anonymous IP addresses to help diagnose problems with our server, to administer our site, or to display the content according to your preferences. Traffic and transaction information may also be shared with business partners and advertisers on an aggregate and anonymous basis.
<br>(c) USE OF COOKIES. We may use cookies to deliver content specific to your interests or for other purposes. Promotions or advertisements displayed on our site may contain cookies. We do not have access to or control over information collected by outside advertisers on our site.
<br>(d) DISCLOSURE OF PERSONAL INFORMATION. We may disclose personal information if required to do so by law or in the good-faith belief that such action is necessary to (1) conform to the edicts of the law or comply with legal process served on Company or its parent company, subsidiaries or affiliates, (2) protect and defend the rights or property of Company or the users of the Site, or (3) act under exigent circumstances to protect the safety of the public or users of the Site.
<br>(e) SALE OF INFORMATION. In order to accommodate changes in our business, we may sell or buy portions of our company or other companies or assets, including the information collected through this Web site. If Company or substantially all of its assets are acquired, customer information will be one of the assets transferred to the acquirer.
<br>(f) ACCESS TO INFORMATION. Unfortunately, we do not maintain any procedures for you to review or request changes to the information that we collect about you, except that you may request that we remove all information about you from our database by contacting us in accordance with Section 6.1 below.</p>
          <p class="mb-5">4. SECURITY</p>
          <p class="mb-5">The Site has security measures in place to prevent the loss, misuse, and alteration of the information that we obtain from you, but we make no assurances about our ability to prevent any such loss, misuse, to you or to any third party arising out of any such loss, misuse, or alteration.</p>
          <p class="mb-5">5. WEBSITE AREAS BEYOND COMPANY’S CONTROL</p>
          <p class="mb-5">5.1. PUBLIC COMMENTS</p>
          <p class="mb-5">The Site may include interactive sections such as commenting areas where visitors to the site can post comments. Please remember that any information that is disclosed in these areas becomes public information and you should exercise caution when deciding to disclose your personal information.</p>
          <p class="mb-5">5.2. THIRD PARTY WEBSITES</p>
          <p class="mb-5">The Site contains links to other websites. If you choose to visit other websites, we have no control over the privacy practices or content of those other websites, and it is your responsibility to review the privacy policies at those websites to confirm that you understand and agree with their policies.</p>
          <p class="mb-5">6. CONTACT INFORMATION AND POLICY UPDATES</p>
          <p class="mb-5">6.1. CONTACTING US</p>
          <p class="mb-5">If you have any questions about this Policy, our practices related to this Site, or if you would like to have us remove your information from our database please feel contact us at the following:</p>
          <p class="mb-5">info@kirehab.com</p>
          <p class="mb-5">Daily Feel Goods LLC</p>
          <p class="mb-5">11834 Treewind Ct <br> San Diego <br> CA 9212</p>
          <p class="mb-5">We reserve the right, at any time, to add to, change, update, or modify this Policy, simply by posting such change, update, or modification on the Site and without any other notice to you. Any such change, update, or modification will be effective immediately upon posting on the Site. It is your responsibility to review this Policy from time to time to ensure that you continue to agree with all of its terms.</p>
          <h5 class="font-bold mb-2">Medical Disclaimer</h5>
          <p class="mb-5">The information provided on this website and in our products should not be taken as medical advice.<br>
All activities performed by an individual is done by their sole discretion, knowing the potential hazards of performing an exercise program.
</p>
          <p class="mb-5">A medical evaluation should occur before taking part in any new exercise training program. Daily Feel Goods LLC will not be held liable for injuries, harm, or even death due to performing an exercise or training program or any of the drills, stretches, or activities demonstrated on Daily Feel Goods LLC.
By purchasing from or using content from Daily Feel Goods LLC, I understand that I am not purchasing diagnostic services, medical advice, or individualized recommendations. Daily Feel Goods LLCt’s Programs are not meant to treat or manage any health condition or injury. Always consult with your healthcare provider prior to beginning a new training program. The information contained herein is not intended to be used as medical advice or to replace medical advice. DeLuca Mull Chiropractic Daily Feel Goods LLC, Inc will not be liable for any direct, indirect, consequential, special, exemplary, or other damages arising from the previous statement.
By participating in any of our programs or using any of the information on this site, you are agreeing to these terms.
</p>
          
        </template>
      </RoundedBigCard>
    </ion-content>
  </ion-page>
</template>

<script>
import PageHeader from "@/components/shared/PageHeader";
import RoundedBigCard from "@/components/shared/RoundedBigCard";
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon";

export default {
  name: "PrivacyModal",
  components: {ArrowLeftIcon, RoundedBigCard, PageHeader},
  methods: {
    closeModal() {
      return this.$ionic.modalController.dismiss();
    }
  }
}
</script>

<style scoped>

</style>
