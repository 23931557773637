<template>
  <ion-page>
    <ion-content>
      <PageHeader bg="bg-yellow" title="Terms of Service">
        <template slot="button">
          <button @click="closeModal" class="mb-2">
            <ArrowLeftIcon />
          </button>
        </template>
      </PageHeader>
      <RoundedBigCard class="-mt-8 relative z-10">
        <template slot="content">
            <h5 class="font-bold mb-2">Terms & Conditions In Short</h5>
            <p class="mb-5">
            <ul class="list-disc">
              <li>By ordering any of our products, you agree to be bound by these terms & conditions.</li>
              <li>By placing an order at Daily Feel Goods, LLC, you warrant that you are at least 18 years old or have parents’ permission to purchase from us.</li>
              <li>All personal information you provide us with or that we obtain will be handled by Daily Feel Goods, LLC as responsible for the personal information.</li>
              <li>Events outside Daily Feel Goods LLC’s control shall be considered force majeure.</li>
              <li>The price applicable is that set at the date on which you place your order.</li>
              <li>Shipping costs and payment fees are recognized before confirming the purchase.</li>
              <li>Card information is transmitted over secure SSL encryption and is not stored.</li>
              <li>Please note that local charges may occur.</li>
              <li>The Daily Feel Goods LLC reserves the right to amend any information without prior notice.</li>
              <li>All participation in activities demonstrated in videos or recommended in training programs is done voluntarily by the participant, with full knowledge of the risks associated in physical exertion activities.</li>
              <li>The information provided on this website is no medical advice. Medical problems should be addressed by a medical provider and medical evaluation.</li>
            </ul> 
            </p>

            <h5 class="font-bold mb-2">Terms & Conditions</h5>
        
            <p class="mb-5">This page contains the terms & conditions. Please read these terms & conditions carefully before ordering any products from us. You should understand that by ordering any of our products, you agree to be bound by these terms & conditions.
By placing an order at Daily Feel Goods LLC, you warrant that you are at least 18 years old (or have parents’ permission to purchase from us) and accept these terms & conditions which shall apply to all orders placed or to be placed at Daily Feel Goods LLC for the sale and supply of any products. None of these terms & conditions affect your statutory rights. No other terms or changes to the terms & conditions shall be binding unless agreed in writing signed by us.</p>
            <h5 class="font-bold mb-2">Personal Information</h5>
            
            <p class="mb-5">All personal information you provide us with or that we obtain will be handled by Daily Feel Goods LLC as responsible for the personal information. The personal information you provide will be used to ensure deliveries to you, the credit assessment, to provide offers and information on our catalog to you. The information you provide is only available to Daily Feel Goods LLC and will not be shared with other third parties. You have the right to inspect the information held about you. You always have the right to request Daily Feel Goods LLC to delete or correct the information held about you. By accepting the Daily Feel Goods LLCt Conditions, you agree to the above.</p>
            <h5 class="font-bold mb-2">Force Majeure</h5>
            <p class="mb-5">Events outside Daily Feel Goods LLC’s control, which is not reasonably foreseeable, shall be considered force majeure, meaning that Daily Feel Goods LLC is released from Kineti Impact’s obligations to fulfill contractual agreements. Example of such events are government action or omission, new or amended legislation, conflict, embargo, fire or flood, sabotage, accident, war, natural disasters, strikes or lack of delivery from suppliers. The force majeure also includes government decisions that affect the market negatively and products, for example, restrictions, warnings, ban, etc.</p>
            <h5 class="font-bold mb-2">Payment</h5>
            <p class="mb-5">All products remain Daily Feel Goods LLC’s property until full payment is made. The price applicable is that set at the date on which you place your order. Shipping costs and payment fees are recognized before confirming the purchase. If you are under 18 years old you must have parents’ permission to buy from Daily Feel Goods LLC.
All transfers conducted through Daily Feel Goods LLC are handled and transacted through third party dedicated gateways to guarantee your protection. Card information is not stored and all card information is handled over SSL encryption. Please read the terms & conditions for the payment gateway chosen for the transaction as they are responsible for the transactions made.
            </p>
            <h5 class="font-bold mb-2">Local Taxes</h5>
            <p class="mb-5">Please note that local charges (sales tax, customs duty) may occur, depending on your region and local customs duties. These charges are at the customer’s own expense.
            </p>
            <h5 class="font-bold mb-2">Cookies</h5>
            <p class="mb-5">Daily Feel Goods LLC uses cookies according to the new Electronic Communications Act, which came into force on 25 July 2003. A cookie is a small text file stored on your computer that contains information that helps the website to identify and track the visitor. Cookies do no harm to your computer, consist only of text, can not contain viruses and occupies virtually no space on your hard drive. There are two types of cookies: “Session Cookies” and cookies that are saved permanently on your computer.
The first type of cookie commonly used is “Session Cookies”. During the time you visit the website, our web server assigns your browser a unique identifier string so as not to confuse you with other visitors. A “Session Cookie” is never stored permanently on your computer and disappears when you close your browser. To use Daily Feel Goods LLC without troubles you need to have cookies enabled.
The second type of cookie saves a file permanently on your computer. This type of cookie is used to track how visitors move around on the website. This is only used to offer visitors better services and support. The text files can be deleted. On Daily Feel Goods LLC we use this type of cookie to keep track of your shopping cart and to keep statistics of our visitors. The information stored on your computer is only a unique number, without any connection to personal information.
</p>
            <h5 class="font-bold mb-2">Additional Information</h5>
            <p class="mb-5">Daily Feel Goods LLC reserves the right to amend any information, including but not limited to prices, technical specifications, terms of purchase and product offerings without prior notice. At the event of when a product is sold out, Daily Feel Goods LLC has the right to cancel the order and refund any amount paid in the best way. Daily Feel Goods LLC shall also notify the customer of equivalent replacement products if available.
<br>All inquiries: info@kirehab.com
</p>
            <h5 class="font-bold mb-2">Return Policy/Workshop Refund Policy</h5>
            <p class="mb-5">A full refund for a workshop registration fee will be given if requested greater than 1 month in advance. No refunds will be given with less than 30 days notice. However, registration can be transferred to another participant up to the day of the workshop.
There are no refunds on discounted products.
There are no refunds on training programs.
You are entitled to an exchange or refund within 14 days of your purchase for unworn, unused, and unwashed physical items. Please note that the product must be returned unused, in original packaging including all labels. In case of refund neither the original shipping cost nor the return shipping cost will be repaid. You can find the return address under kirehab.com
</p>

            
        </template>
      </RoundedBigCard>
    </ion-content>
  </ion-page>
</template>

<script>
import PageHeader from "@/components/shared/PageHeader";
import RoundedBigCard from "@/components/shared/RoundedBigCard";
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon";

export default {
  name: "TermsModal",
  components: {ArrowLeftIcon, RoundedBigCard, PageHeader},
  methods: {
    closeModal() {
      return this.$ionic.modalController.dismiss();
    }
  }
}
</script>
