<template>
  <ion-page>
    <ion-content>
      <PageHeader bg="bg-yellow" title="Get Help">
        <template slot="button">
          <button @click="closeModal" class="mb-2">
            <ArrowLeftIcon/>
          </button>
        </template>
      </PageHeader>
      <RoundedBigCard class="-mt-8 relative z-10">
        <template slot="content">
          <p class="mb-5">Report a problem. We will help you as soon as possible.</p>
          
        <ValidationObserver ref="helpRequestForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(sendText)" method="post">
            <div>
              <ValidationProvider mode="eager" rules="required" vid="text" name="Text" v-slot="{ errors }">
                <textarea
                  v-model="form.text"
                  type="text"
                  class="form-textarea w-full h-96 resize-y"
                  :class="errors.length ? 'border border-red-600' : ''"
                  placeholder="Insert your request here"
                  rows="8"
                />
                <p v-show="errors.length" class="w-full text-red-600 mt-1 text-sm"> 
                  The input text is invalid
                </p>
              </ValidationProvider>
            </div>

            <div class="mt-6">
              <button class="form-input flex items-center bg-yellow" :class="{'opacity-75': loading}" type="submit">
                <span class="w-full text-gray-100 text-center">
                  {{ loading === false ? 'Send Request' : 'Sending request...'}}
                </span>
              </button>
            </div>
          </form>
        </ValidationObserver>


        </template>
      </RoundedBigCard>
    </ion-content>
  </ion-page>
</template>

<script>
import PageHeader from "@/components/shared/PageHeader";
import RoundedBigCard from "@/components/shared/RoundedBigCard";
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon";
import { HELP_REQUEST } from '@/store/types/actions'
import { mapActions } from 'vuex'

export default {
  name: "HelpModal",
  components: {ArrowLeftIcon, RoundedBigCard, PageHeader},
  data() {
    return {
        form: {
          text: ''
        },

        loading: false
      }
  },
  methods: {

    ...mapActions({
        sendHelpRequest: `auth/${HELP_REQUEST}`
      }),

    sendText() {
      this.loading = true

      this.sendHelpRequest({text: this.form.text})
        .then(() => {
          this.form.text = ''
          this.loading = false
          this.$refs.helpRequestForm.reset()
        })
        .catch(error => {
          this.loading = false

          if (error.response && error.response.data) {
            this.$refs.helpRequestForm.setErrors(error.response.data.errors.error)
          }

          this.$ionic
            .toastController.create({
              color: 'danger',
              message: 'There was an error while trying to send your request',
              duration: 3000,
            })
            .then(toast => toast.present())
        })
    },

    closeModal() {
      return this.$ionic.modalController.dismiss();
    }
  }
}
</script>
