<template>
  <ion-page>
    <ion-content class="relative hide-overflow" :scroll-y="false"
      :fullscreen="true">
      <PageHeader bg="bg-yellow"/>
      <RoundedBigCard class="-mt-6 relative z-10" :is-home="true">
        <template slot="content">
          <div class="relative">
            <UserInfo />
            <div class="w-full flex flex-col pt-52">
              <div class="mb-4 hlg:mb-10">
                <ListItem text="Get Help" @click="openHelpModal"/>
                <ListItem text="Privacy Policy" @click="openPrivacyModal"/>
                <ListItem text="Terms of Service" @click="openTermsModal"/>
              </div>
              <ListItem text="Sign out" @click="signoutAlert"/>
            </div>
          </div>
        </template>
      </RoundedBigCard>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions } from 'vuex'
import { SIGN_OUT } from '@/store/types/actions'
import PrivacyModal from "@/components/shared/modals/PrivacyModal"
import HelpModal from "@/components/shared/modals/HelpModal"
import TermsModal from "@/components/shared/modals/TermsModal"

export default {
  name: "User",

  components: {
    UserInfo: () => import('@/components/user/UserInfo'),
    ListItem: () => import('@/components/user/ListItem'),
    PageHeader: () => import('@/components/shared/PageHeader'),
    RoundedBigCard: () => import('@/components/shared/RoundedBigCard')
  },

  methods: {
    ...mapActions({
      signOut: `auth/${SIGN_OUT}`
    }),

    openHelpModal() {
      return this.$ionic.modalController
        .create({
          component: HelpModal,
          componentProps: {
            parent: this.$root,  
          }
        })
        .then(modal => modal.present())
    },

    openPrivacyModal() {
      return this.$ionic.modalController
        .create({
          component: PrivacyModal,
          componentProps: {
            parent: this.$root,  
          }
        })
        .then(modal => modal.present())
    },

    openTermsModal() {
      return this.$ionic.modalController
        .create({
          component: TermsModal,
          componentProps: {
            parent: this.$root,  
          }
        })
        .then(modal => modal.present())
    },

    signoutAlert() {
      return this.$ionic.alertController
        .create({
          header: 'Sign Out',
          message: 'Are you sure you want to sign out?',
          cssClass: 'user-alert',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                this.$ionic.alertController.dismiss()
              },
            },

            {
              text: 'Sign Out',
              handler: async () => {
                await this.signOut()
                this.$router.push({ name: 'signIn' });
              },
            },
          ]
        })
        .then(alert => alert.present())
    }
  },
}
</script>

<style scoped>
.hide-overflow {
  --overflow: hidden !important;
}
</style>